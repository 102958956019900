import React, { FunctionComponent } from 'react';


interface Props {
  /** Sets the skeleton-pulse style */
  type?: 'text' | 'image' | 'button' | 'button-secondary';
  /** Sets the width of the skeleton. Default unit is px. */
  width?: number | string;
  /** Sets the height of the skeleton. Default unit is px. */
  height?: number | string;
  /** Makes the skeleton look like a circle */
  circle?: boolean;
  /** Override classNames on wrapper */
  className?: string;
  /** Override styles on wrapper */
  style?: object;
  [rest: string]: unknown; // ...rest property
}

const getValidCSSInput = (input: string | number) => {
  switch (typeof input) {
    case 'undefined':
    case 'boolean':
      return null;
    case 'number':
      return `${input}px`;
    default:
      const parsedInput = parseFloat(input);
      const unit = input.match(/%|em/);
      return isNaN(parsedInput) ? '' : unit ? `${parsedInput}${unit}` : `${parsedInput}px`;
  }
};

const CustomSkeleton: FunctionComponent<Props> = ({
  width,
  height,
  circle,
  type = 'text',
  className = '',
  style = {},
  ...rest
}) => {
  const isTextType = type === 'text';
  const classNames = `pulse ${!className ? type : ``} ${isTextType ? 'transform' : ''} ${type === 'button-secondary' && !className ? 'button' : ''} ${circle ? 'circle' : ''} ${className}`;

  return (
    <span
      {...rest}
      className={classNames}
      style={{
        ...style,
        //@ts-ignore
        width: `${getValidCSSInput(width) ?? ''}`,
        //@ts-ignore
        height: `${getValidCSSInput(height) ?? ''}`,
        borderRadius: '4px'
      }}
    />
  );
};

export default CustomSkeleton;
