import { AttributeValue } from '@msdyn365-commerce/retail-proxy';

export const getBVAttributeValues = (attributeValues: AttributeValue[] | undefined, key: string): number => {
    const value = +(attributeValues?.find(attr => attr.Name?.trim() === key)?.TextValue || 0);
    return Math.round(value * 10) / 10;
};

export const getBVAttributeValuesUnbxd = (attributeValues: any | undefined, key: string): number => {
    const value = +(attributeValues[key] ?? 0);
    return Math.round(value * 10) / 10;
};
