import { ICoreContext, IGeneric, IAny } from '@msdyn365-commerce/core';
import { AttributeValue, CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { IBrandListData } from '../../../../../modules/mfrm-buybox/mfrm-buybox.view';
import { _getSoonestDeliveryDate, _makeDeliveryMessage } from '../../../../../Utilities/product-search-result-utils';
import { ProductSearchResultExtended } from '../../../custom-components/product-search-result-items';
import CustomSkeleton from '../../../custom-components/CustomSkeleton';
interface IDeliveryMessage {
    product?: ProductSearchResultExtended;
    context: ICoreContext<IGeneric<IAny>>;
    isMasterProduct?: boolean;
    attributeValues?: AttributeValue[];
    extensionProperties?: CommerceProperty[];
    lowestPriceVariantRecordId?: string;
}

//TODO: common method; move to general utility file. AND change return type to an Enumeration
export const _getProductType = (shippingInformation: string | undefined): string => {
    if (shippingInformation && shippingInformation.toLowerCase() === 'delivery') {
        return 'Core';
    }
    if (shippingInformation && shippingInformation.toLowerCase() === 'parcel') {
        return 'Small Parcel';
    }
    if (shippingInformation && shippingInformation.toLowerCase() === 'drop ship') {
        return 'Drop Ship';
    }
    return '';
};

export const computeItemLines = (
    product: IDeliveryMessage['product'],
    defaultVariantId: number | undefined,
    lowestPriceVariantRecordId: string | undefined
) => {
    if (product?.IsMasterProduct) {
        if (defaultVariantId && defaultVariantId !== 0) {
            return defaultVariantId.toString();
        }
        if (lowestPriceVariantRecordId && lowestPriceVariantRecordId !== '') {
            return lowestPriceVariantRecordId;
        }
    }
    return product?.RecordId.toString();
};

const DeliveryMessage: React.FC<IDeliveryMessage> = ({
    product,
    context,
    attributeValues,
    extensionProperties,
    lowestPriceVariantRecordId
}) => {
    let productDeliveryMessage = '';
    const { app } = context;
    const shippingInformationSmall = attributeValues && attributeValues.find(
        (attr: AttributeValue) => attr.Name?.trim() === app.config.smallParcelAttributeKey
    )?.TextValue?.toLowerCase();

    const defaultVariantShippingInformation = extensionProperties && extensionProperties.find(
        (property: CommerceProperty) => property?.Key === "Unbxd_DefaultVariantShippingInformation"
    )?.Value?.StringValue;

    if ((_getProductType(defaultVariantShippingInformation || shippingInformationSmall)) === 'Core') {
        productDeliveryMessage = product?.deliveryByMessage ?? '';
    } else if ((
        (_getProductType(defaultVariantShippingInformation || shippingInformationSmall)) === 'Small Parcel' ||
        (_getProductType(defaultVariantShippingInformation || shippingInformationSmall)) === 'Drop Ship'
    )) {
        let deliveryDays =
            attributeValues && attributeValues.find(attr => attr.Name?.trim() === app.config.leadTime)?.TextValue;
        if (
            (deliveryDays === '' || deliveryDays === undefined || deliveryDays === null) &&
            app.config.brandList &&
            app.config.brandList.length > 0
        ) {
            const brand =
                attributeValues &&
                attributeValues.find(
                    attr =>
                        app.config.brandBackofficeAttributePlp &&
                        attr.Name?.trim() === app.config.brandBackofficeAttributePlp
                )?.TextValue?.toLowerCase();

            if (brand !== undefined && brand !== null && brand !== '') {
                app.config.brandList.map((singleBrand: IBrandListData) => {
                    if (singleBrand.brandName.toLowerCase() === brand) {
                        deliveryDays =
                            singleBrand.brandLeadtime !== undefined &&
                                singleBrand.brandLeadtime !== '' &&
                                singleBrand.brandLeadtime !== null
                                ? singleBrand.brandLeadtime
                                : '';
                    }
                });
            }
            deliveryDays = app.config.brandList[0].brandLeadtime;
        }
        if (deliveryDays && productDeliveryMessage === '') {
            productDeliveryMessage = _makeDeliveryMessage(deliveryDays);
        }
    }

    return productDeliveryMessage ? (
        <div className='msc-product__delivery-message'>
            {productDeliveryMessage}
        </div>
    ) : <CustomSkeleton type="text" className='skeleton-dimensions__delivery'/>;
};

export default DeliveryMessage;