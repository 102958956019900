import * as React from "react";
import { ITelemetry } from '@msdyn365-commerce/core';
interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage: string;
}

interface ErrorBoundaryProps {
    showMessage?: boolean | string; //true to show the error message, set to string to show custom message
    telemetry: ITelemetry
}


// Put this in a child component to mock an error in order to test ErrorBoundary.
export const MockError = (props: {message?: string}) => {
  throw new Error(props.message);
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ""
    };
  }


  // @ts-ignore
  private static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

//   public componentDidCatch(error: any, errorInfo: any) {
//     // You can also log the error to an error reporting service
//     logErrorToMyService(error, errorInfo);
//   }

  public render() {
    const bgColor = "rgba(0,0,255)";

    if (this.state.hasError) {
      const { showMessage } = this.props;
      const message = typeof showMessage === 'string' ? showMessage : showMessage ? this.state.errorMessage : false;
      this.props?.telemetry?.error(this.state?.errorMessage);
      // TODO design this custom UI fallback
      return message ? <div style={{
        background: bgColor,
        padding: "5px",
        color: "white"
      }}>{message}</div> : null;
    }

    return this.props.children || null;
  }
}
