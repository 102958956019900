import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import {
    Button,
    format,
    getPayloadObject,
    getTelemetryAttributes,
    ITelemetryContent,
    onTelemetryClick
} from '@msdyn365-commerce-modules/utilities';
import { PriceComponent, RatingComponent, IPriceComponentResources } from '@msdyn365-commerce/components';
import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import MsDyn365, {
    IComponent,
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    IImageProps,
    Image,
    IRequestContext,
    msdyn365Commerce,
    RichTextComponent
} from '@msdyn365-commerce/core';
import { AttributeValue, ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
// VSI Customization - starts
import { convertToTitlecase } from '../../../../Utilities/convert-to-titlecase';
import { getBVAttributeValues } from '../../../../Utilities/get-bv-attributes';
import { buildProductBadge, parseBadgeData } from '../../../../Utilities/get-product-badge';
import { parseBrandName } from '../../../../Utilities/get-product-brand';
import {
    _coreProductATPCall,
    _getEyebrowMessage,
    _getSoonestDeliveryDate,
    _makeDeliveryMessage
} from '../../../../Utilities/product-search-result-utils';
import { FinancingPerMonthComponent } from '../../custom-components/financing-per-month-component';
import DeliveryMessage from './delivery-message';
import { BVRatingSummary } from '../../../../Utilities/components/BVRatingSummary';
import { removeSpecialChars } from '../../../../Utilities/allRegex';
import CustomSkeleton from '../../custom-components/CustomSkeleton';
import { ErrorBoundary } from '../../../../common/components/error-boundary';
// VSI Customization - ends
export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
    quickViewButton?: React.ReactNode;
    eyebrowMessage?: string | undefined;
    badgeAttribute?: string;
    deliveryMessage?: string;
    isRefinersEnabled?: boolean;
    // VSI Customization - Copied following 3 props from OOTB product.component to be used in product recommendations for Cart
    ratingCountAriaLabel?: string;
    isPriceMinMaxEnabled?: boolean;
    priceResources?: IPriceComponentResources;
    // Customized props for product recommendation on Cart page
    displayShopNow?: boolean;
    showBvRatings?: boolean;
    shopNowBtnText?: string;
}

export interface IEyebrowMessageDimensions {
    height: number;
    visibleHeight: number;
}

export interface IProductComponent extends IComponent<IProductComponentProps> { }

const PriceComponentActions = {};

// tslint:disable-next-line: max-func-body-length cyclomatic-complexity
const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent,
    quickViewButton,
    eyebrowMessage,
    badgeAttribute,
    deliveryMessage,
    isRefinersEnabled,
    displayShopNow,
    shopNowBtnText,
    showBvRatings
}) => {
    // VSI Customization - starts
    const {
        app: {
            config: { brandBackofficeAttributePlp, enableAttributeBasedRating }
        }
    } = context;
    const getEyebrowElementRef = React.useRef<HTMLDivElement>(null);
    const eyebrowElement = getEyebrowElementRef.current?.querySelector('.mfrm-eyebrow__button-plp') as Element;
    const [isTruncated, setTruncated] = React.useState<IEyebrowMessageDimensions>({
        height: eyebrowElement?.scrollHeight,
        visibleHeight: eyebrowElement?.clientHeight
    });

    const badgeAttributeToUse = context.app.config.productBadgeAttribute;
    // VSI Customization - ends
    const product = data.product;
    if (!product) {
        return null;
    }
    // VSI Customization - starts
    /** When enabled rating values will be picked from BazaarVoice attributes created on product.
     * If disabled rating will be shown from OOTB properties on a product.
     * This will be used for PLP and product-collection/product recommendations
     */
    const { AttributeValues, ExtensionProperties } = product;
    let isBVratings = false;
    if (AttributeValues && AttributeValues?.find(attr => attr.Name?.trim() === "BVAverageOverallRating")) {
        isBVratings = true;
    }
    const averageRating = enableAttributeBasedRating && AttributeValues && isBVratings ? getBVAttributeValues(AttributeValues, 'BVAverageOverallRating') : _parseValue(product?.AverageRating);
    const totalRatings = enableAttributeBasedRating && AttributeValues && isBVratings ? getBVAttributeValues(AttributeValues, 'BVTotalReviewCount') : _parseValue(product?.TotalRatings);
    React.useEffect(() => {
        function handleResize(): void {
            setTruncated({
                height: eyebrowElement?.scrollHeight,
                visibleHeight: eyebrowElement?.clientHeight
            });
        }
        handleResize();
        if (MsDyn365.isBrowser) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        } else {
            return () => null;
        }
    }, [eyebrowElement?.scrollHeight, eyebrowElement?.clientHeight]);
    // VSI Customization - ends
    const lowestPriceVariantRecordId = AttributeValues?.find(
        attr => attr.Name?.trim() === context.app.config.EcomLowestPriceVariantRecIdKey
    )?.TextValue;
    const unbxdDefaultVariant = product && product.ExtensionProperties?.find((property: CommerceProperty) => property?.Key === "Unbxd_DefaultVariantID")?.Value?.LongValue;
    const queryBasedSelection = context.app.config.queryBasedVariantSelection;
    const fallBackImage = context.app.config.placeholderImageName;
    const defaultOrLowestVariantRecordId = unbxdDefaultVariant! > 0 ? unbxdDefaultVariant : product.RecordId;
    let productUrl = getProductPageUrlSync(removeSpecialChars(product?.Name!, context) || '', queryBasedSelection ? product.MasterProductId || product.RecordId : product.RecordId, context && context.actionContext, undefined);

    if (allowBack) {
        productUrl = updateProductUrl(productUrl, context);
    }
    if (queryBasedSelection) {
        const variantIdQueryParams = productUrl.includes('?') ? `&variantid=${defaultOrLowestVariantRecordId}` : `?variantid=${defaultOrLowestVariantRecordId}`;
        productUrl += variantIdQueryParams;
    }
    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', defaultOrLowestVariantRecordId!.toString());
    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);
    // VSI Customization -starts
    // #17772: Check the master product, if false then add size Refinement property otherwise show Starting at
    let sizeValue =
        product && !product?.IsMasterProduct && product?.ExtensionProperties && product?.ExtensionProperties?.length > 0
            ? product?.ExtensionProperties.find(i => i.Key === "Size")?.Value?.StringValue
            : '';
    sizeValue = sizeValue && convertToTitlecase(sizeValue);

    // Flag used on cart page to display ratings & shop now button
    const isCustomizedDesign = displayShopNow;

    if (typeName === 'product-collection') {
        // VSI Customization -starts
        const brandName = parseBrandName(AttributeValues, brandBackofficeAttributePlp);
        const productTitleWithBrandname = brandName ? `${brandName} ${product.Name}` : product.Name;
        // VSI Customization -end
        // tslint:disable:max-line-length
        return (
            <ErrorBoundary telemetry={context.telemetry}>
                <div className='msc-product'>
                    <a
                        href={productUrl}
                        onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                        aria-label={renderLabel(
                            product.Name,
                            context.cultureFormatter.formatCurrency(product.Price),
                            product.AverageRating,
                            ratingAriaLabel
                        )}
                        {...attribute}
                    >
                        {<div className='mfrm-product-badge'>{renderProductBadge(badgeAttributeToUse, AttributeValues, context.request)}</div>}
                        <div className='msc-product__image'>
                            {renderProductPlacementImage(
                                imageSettings, context.request.gridSettings, product.PrimaryImageUrl,
                                product.PrimaryImageUrl, product.Name, context.actionContext.requestContext, fallBackImage)}
                        </div>
                    </a>
                    <div className='msc-product__details'>
                        {/* VSI Customization - START */}
                        {/* (ADO:23447) Added only class name conditionally as discounted price based on product.component if BasePrice > Price then it will be discounted else not */}
                        <a
                            className={
                                product.BasePrice
                                    ? product.BasePrice > product.Price
                                        ? 'msc-product discounted'
                                        : 'msc-product'
                                    : 'msc-product'
                            }
                            href={productUrl}
                            onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                            aria-label={renderLabel(
                                product.Name,
                                context.cultureFormatter.formatCurrency(product.Price),
                                product.AverageRating,
                                ratingAriaLabel
                            )}
                            {...attribute}
                        >
                            {/* Todo: 'Starting at' string will be replaced with relevant resources  */} {/* For cart page, price will be shown after name */}
                            {!isCustomizedDesign &&
                                <>
                                    {sizeValue ? sizeValue : product.Price !== 0 ? 'Starting at ' : ''}
                                    {renderPrice(
                                        context,
                                        typeName,
                                        id,
                                        product.BasePrice,
                                        product.Price,
                                        savingsText,
                                        freePriceText,
                                        originalPriceText,
                                        currentPriceText
                                    )}
                                </>
                            }
                            {!isCustomizedDesign &&
                                <span className='msc-product__title' title={productTitleWithBrandname}>
                                    {productTitleWithBrandname && productTitleWithBrandname.length > 40
                                        ? `${productTitleWithBrandname.slice(0, 40)}...`
                                        : productTitleWithBrandname}
                                </span>
                            }
                            {isCustomizedDesign &&
                                <span className='msc-product__title' title={productTitleWithBrandname}>
                                    {productTitleWithBrandname}
                                </span>
                            }
                            {isCustomizedDesign &&
                                <div>
                                    {renderPrice(
                                        context,
                                        typeName,
                                        id,
                                        product.BasePrice,
                                        product.Price,
                                        savingsText,
                                        freePriceText,
                                        originalPriceText,
                                        currentPriceText
                                    )}
                                </div>
                            }
                            {/* todo : will be added when required in next sprints */}
                            {!context.app.config.hideRating &&
                                renderRating(context, typeName, id, averageRating, totalRatings, ratingAriaLabel)}
                            {isCustomizedDesign &&
                                <Button color='primary' type='button' href={productUrl} >
                                    {shopNowBtnText}
                                </Button>
                            }
                            {/* VSI Customization - END */}
                        </a>
                    </div>
                    {/* OOB Quick View */}
                    {/* {quickViewButton && renderQuickView(quickViewButton, product.RecordId)} */}
                </div>
            </ErrorBoundary>
        );
    } else {
        const priceComponent = renderPrice(
            context,
            typeName,
            id,
            product.BasePrice,
            product.Price,
            savingsText,
            freePriceText,
            originalPriceText,
            currentPriceText
        );
        const isSearchResultContainer = typeName === 'search-result-container' || typeName === 'mfrm-search-result-container';
        const brandName = parseBrandName(AttributeValues, brandBackofficeAttributePlp);
        return (
            // tslint:disable-next-line: react-this-binding-issue jsx-no-lambda
            <ErrorBoundary telemetry={context.telemetry}>
                <div className='msc-product' role='button' onClick={() => setProductUrl(productUrl)}>
                    <a
                        href={productUrl}
                        onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                        aria-label={renderLabel(
                            product.Name,
                            context.cultureFormatter.formatCurrency(product.Price),
                            product.AverageRating,
                            ratingAriaLabel
                        )}
                        {...attribute}
                    >
                        {isSearchResultContainer && (
                            <div className='mfrm-product-badge'>
                                {renderProductBadge(badgeAttributeToUse, AttributeValues, context.request)}
                            </div>
                        )}
                        <div className='msc-product__image'>
                            {renderProductPlacementImage(
                                imageSettings, context.request.gridSettings, product.PrimaryImageUrl,
                                product.PrimaryImageUrl, product.Name, context.actionContext.requestContext, fallBackImage)}
                        </div>
                    </a>

                    <div className='msc-product__details'>
                        {/* VSI Customization - START */}
                        <div className='msc-product__rating-holder'>
                            {/* VSI Customization - END */}
                            {isSearchResultContainer && eyebrowMessage && (
                                <div className='msc-product__rating-eyebrow-wrapper'>
                                    <div className='mfrm-eyebrow__button' ref={getEyebrowElementRef}>
                                        <RichTextComponent text={eyebrowMessage} className='mfrm-eyebrow__button-plp' />
                                        {isTruncated.height > isTruncated.visibleHeight ? (
                                            <RichTextComponent text={eyebrowMessage} className='mfrm-eyebrow__tooltip-title' />
                                        ) : (
                                            false
                                        )}
                                    </div>
                                </div>
                            )}
                            {isSearchResultContainer && !eyebrowMessage && eyebrowMessage?.length !== 0 && !showBvRatings && (
                                <CustomSkeleton type="text" className='skeleton-dimensions__eyebrow' />
                            )}
                            {isSearchResultContainer && eyebrowMessage?.length === 0 && !showBvRatings && (
                                <div className='msc-product__rating-eyebrow-wrapper'>
                                    <div>&nbsp;</div>
                                </div>
                            )}
                            {!context.app.config.hideRating && (data.product && showBvRatings) ? <BVRatingSummary itemId={data.product.ItemId} variantId={data.product.RecordId} /> :
                                (renderRating(context, typeName, id, averageRating, totalRatings, ratingAriaLabel) ? renderRating(context, typeName, id, averageRating, totalRatings, ratingAriaLabel) :
                                    <div className='msc-rating  msc-no-ratings'>
                                        <div>&nbsp;</div>
                                    </div>
                                )}
                            {/* VSI Customization - START */}
                        </div>
                        {/* VSI Customization - END */}
                        <a
                            href={productUrl}
                            onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                            aria-label={renderLabel(
                                product.Name,
                                context.cultureFormatter.formatCurrency(product.Price),
                                product.AverageRating,
                                ratingAriaLabel
                            )}
                            {...attribute}
                        >
                            <span className='msc-product__title' title={product.Name}>
                                {brandName ? `${brandName} ${product.Name}` : product.Name}
                            </span>

                            {/* original price */}
                            {!isSearchResultContainer && priceComponent}
                            {renderDescription(product.Description)}
                        </a>
                        {/* {deliveryMessage && <>{deliveryMessage}</>} */}
                        <DeliveryMessage
                            product={data.product}
                            context={context}
                            attributeValues={AttributeValues}
                            extensionProperties={ExtensionProperties}
                            lowestPriceVariantRecordId={lowestPriceVariantRecordId}
                        />
                    </div>
                    {/* new price */}
                    {isSearchResultContainer
                        ? product.Price
                            ? priceComponent && (
                                <FinancingPerMonthComponent
                                    pageType='PLP'
                                    sizeValue={sizeValue}
                                    sellingPrice={product.Price}
                                    context={context}
                                    priceComponent={priceComponent}
                                    activeVariantId={undefined}
                                    productId={undefined}
                                    activeColorVariantId={undefined}
                                />
                            )
                            : priceComponent
                        : null}
                    {/* OOB Quick View */}
                    {/* {quickViewButton && renderQuickView(quickViewButton, product.RecordId)} */}
                </div>
            </ErrorBoundary>
        );
    }
    // VSI Customization - Ends here
};
// VSI Customization - Starts here
function _parseValue(productRating?: string | number): number {
    const value = +(productRating || 0);
    return Math.round(value * 10) / 10;
}
// VSI Customization - Ends here
function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

function setProductUrl(productUrl: string): void {
    if (MsDyn365.isBrowser) {
        window.location.href = productUrl;
    }
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

// Render Quick View
// function renderQuickView(quickview: React.ReactNode, item?: number): JSX.Element | undefined {
//     if (quickview === null) {
//         return undefined;
//     }
//     return React.cloneElement(quickview as React.ReactElement, { selectedProductId: item });
// }

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
        const roundedRating = rating.toFixed(2);
        return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context: ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = 'back=true';
    if (srcUrl.search) {
        srcUrl.search += `&${queryString}`;
    } else {
        srcUrl.search += queryString;
    }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

function renderRating(
    context: ICoreContext,
    typeName: string,
    id: string,
    avgRating?: number,
    totalRatings?: number,
    ariaLabel?: string
): JSX.Element | null {
    if (avgRating && avgRating !== 0) {
        const numRatings = (totalRatings && totalRatings.toString()) || '0';
        const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

        return (
            <RatingComponent
                context={context}
                id={id}
                typeName={typeName}
                avgRating={avgRating}
                ratingCount={numRatings}
                readOnly
                ariaLabel={ratingAriaLabel}
                data={{}}
            />
        );
    } else {
        return null;
    }
}

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string,
    currentPriceText?: string
): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };
    return basePrice ? (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    ) : null;
}

/**
    * Gets the react component for product rating.
    * @param  productCardimageSettings - Module image settings for product card.
    * @param  gridSettings - Grid settings defined in theme.
    * @param  imageUrl - Image url.
    * @param  fallbackImageUrl - Fallback url for imge.
    * @param  altText - Image Alt text.
    * @param  requestContext - Request context using the component.
    * @param  fallBackImage - Placeholder Image from App settings
    * @returns React component for product image.
    */
function renderProductPlacementImage(
    productCardimageSettings?: IImageSettings, gridSettings?: IGridSettings,
    imageUrl?: string, fallbackImageUrl?: string, altText?: string, requestContext?: IRequestContext, fallBackImage?: string): JSX.Element | null {

    if (!imageUrl || !gridSettings || !productCardimageSettings) {
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Site level config can be of any type.
    const emptyPlaceHolderImage = fallBackImage || '';
    let fallbackImageSource = fallbackImageUrl;
    if (emptyPlaceHolderImage && fallbackImageUrl) {
        fallbackImageSource = `${fallbackImageUrl},${emptyPlaceHolderImage}`;
    }
    const image: IImageData = {
        src: imageUrl,
        altText: altText ? altText : '',
        fallBackSrc: fallbackImageSource
    };
    const imageProps: IImageProps = { gridSettings };

    imageProps.gridSettings = gridSettings;
    imageProps.imageSettings = productCardimageSettings;
    imageProps.imageSettings.cropFocalRegion = true;
    return (
        <Image
            {...image} {...imageProps} loadFailureBehavior='empty'
            requestContext={requestContext}
            bypassHideOnFailure
        />
    );
}
// VSI Customization - starts

function renderProductBadge(
    attributeName: string | undefined,
    attributes: AttributeValue[] | undefined,
    requestContext: IRequestContext
): JSX.Element | undefined {
    const badgeData =
        attributeName &&
        attributes?.find(
            currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attributeName.toLowerCase().trim()
        )?.TextValue;
    if (!badgeData) {
        return;
    }

    // split badge data into its separate badges
    const badges = badgeData.split(',');

    // parse badge data to get its schedule and image src
    const parsedBadges = badges.map(badge => parseBadgeData(badge, requestContext));
    // build product badge image components
    const finalizedBadges = parsedBadges.map(parsedBadge => parsedBadge && buildProductBadge(parsedBadge, requestContext));

    return finalizedBadges && <>{finalizedBadges}</>;
}

// VSI Customization - ends

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<
    IProductComponent
>('Product', { component: ProductCard, ...PriceComponentActions });

export default ProductComponent;