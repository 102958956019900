import { RichText } from '@msdyn365-commerce/core';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import { calculateFinancing } from './checkout-utils';
dayjs.extend(tz);
dayjs.extend(utc);
dayjs.extend(isBetween);
const format = 'M/D/YYYY';

export interface IFinancingOffer {
    offerName?: string;
    noInterestFor: number;
    minPurchase: number;
    everydayFinancingOfferMessage: string,
    limitedFinancingOfferMessage: string,
    lowCostFinancingOfferMessage: string,
    cashbackFinancingOfferMessage: string,
    monthlyOfferDetails?: RichText;
    financingOfferDescription: RichText;
    disclaimer: RichText;
    offerValidStartDate?: string;
    offerValidEndDate?: string;
    specialCampaignOffer?: IFinancingOfferCampaign;
    checkoutDisplay?: { heading: string; details: string; specialOffer?: string},
    FinancingOfferDataOnPage?: RichText;
    cashBackPercentageAmount?: number;
    cashbackBannerDetails?: RichText;
    planID?: number;
    specialCase36Months?: boolean;
}
export interface ILowCostOffer {
    offerName?: string;
    maxPurchase: number;
    lowCostFinancingOfferMessage: string,
    financingOfferDescription: RichText;
    disclaimer: RichText;
    defaultNumberOfMonths: number;
    lowCostOfferDetailsOnCart?: RichText;
}


export interface IFinancingOfferFormatted {
    lowCostOfferDetailsOnCart?: RichText;
    numOfMonths: number;
    costPerMonth: number;
    startDate?: string;
    endDate?: string;
    isLTO: boolean;
    monthlyOfferDetails?: RichText;
    minPurchase: number;
    lowCostFinancingOfferMessage?: string;
    everydayFinancingOfferMessage?: string;
    limitedFinancingOfferMessage?: string;
    cashbackFinancingOfferMessage?: string;
    financingOfferDescription: RichText;
    disclaimer: RichText;
    specialCampaign?: IFinancingOfferCampaign;
    offerName?: string;
    checkoutDisplay?: { heading: string; details: string; specialOffer?: string },
    FinancingOfferDataOnPage?: RichText;
    isCashBack?: boolean;
    cashBackPercentageAmount?: number;
    cashbackBannerDetails?: RichText;
    specialCase36Months?: boolean;
}

export interface IFinancingOfferCampaign {
    initialMonthlyAmount: number;
    numOfDiscountedMonths: number;
    campaignValidStartDate: string;
    campaignValidEndDate: string;
    enableCampaign: boolean;
    showEverydayFlag: boolean;
}

export interface ISelectFinancingOfferData {
    limitedTimeFinancingOffers: IFinancingOffer[];
    everydayFinancingOffers: IFinancingOffer[];
    lowCostOffer?: ILowCostOffer[]
    totalPrice: number | undefined;
    specialFinancingOffer?: IFinancingOffer[];
    synchronySpecialFinancingCondition?: boolean;
    specialFinancingEnable?: boolean;
    checkoutShowEveryDayOffers?: boolean;
    cashbackFinancingEnable?: boolean;
    cashBackFinancingOffer?: IFinancingOffer[];
}

export interface ICampaignRemainingCost {
    cost: number;
    months: number;
}

export const getValidSpecialCampaign = (financingOffer: IFinancingOfferFormatted): IFinancingOfferCampaign | undefined => {
    if (
        financingOffer.specialCampaign &&
        financingOffer.specialCampaign.enableCampaign &&
        financingOffer.specialCampaign.initialMonthlyAmount &&
        financingOffer.specialCampaign.numOfDiscountedMonths &&
        isCampaignScheduled(financingOffer)
    ) {
        return financingOffer.specialCampaign;
    }

    return undefined;
};

export const getCampaignRemainingCost = (
    campaign: IFinancingOfferCampaign,
    financingOffer: IFinancingOfferFormatted,
    totalPrice: number | undefined
): ICampaignRemainingCost | undefined => {
    if (!totalPrice) {
        return undefined;
    }

    const initialCost = campaign.initialMonthlyAmount * campaign.numOfDiscountedMonths;
    const remainingCost = totalPrice - initialCost;
    const remainingMonths = financingOffer.numOfMonths - campaign.numOfDiscountedMonths;
    const monthlyCost = remainingCost / remainingMonths;

    return {
        cost: monthlyCost,
        months: remainingMonths
    };
};
export const isValidLimitedTimeOffer = (offer: IFinancingOffer): boolean => {
    const startDate = dayjs(offer.offerValidStartDate, format);
    const endDate = dayjs(offer.offerValidEndDate, format).add(1, 'd');

    const startDateCentralTime = startDate.isValid() && dayjs(startDate.format(format), format).tz('America/Chicago').local();
    const endDateCentralTime = endDate.isValid() && dayjs(endDate.format(format), format).tz('America/Chicago').local();

    const now = dayjs();
    return startDateCentralTime && endDateCentralTime && now.isBetween(startDateCentralTime, endDateCentralTime);
};

export const isCampaignScheduled = (offer: IFinancingOfferFormatted): boolean => {
    const tempOffer = Object.create(offer);
    if (tempOffer.specialCampaign?.campaignValidStartDate && tempOffer.specialCampaign?.campaignValidEndDate) {
        tempOffer.startDate = tempOffer.specialCampaign?.campaignValidStartDate;
        tempOffer.endDate = tempOffer.specialCampaign?.campaignValidEndDate;

        return isScheduled(tempOffer);
    }

    return false;
};

export const isScheduled = (offer: IFinancingOfferFormatted): boolean => {
    const startDate = dayjs(offer.startDate, format);
    const endDate = dayjs(offer.endDate, format).add(1, 'd');

    const startDateCentralTime = startDate.isValid() && dayjs(startDate.format(format), format).tz('America/Chicago').local();
    const endDateCentralTime = endDate.isValid() && dayjs(endDate.format(format), format).tz('America/Chicago').local();

    const now = dayjs();

    return startDateCentralTime && endDateCentralTime && now.isBetween(startDateCentralTime, endDateCentralTime);
};

export const selectFinancingOffer = ({ limitedTimeFinancingOffers = [], everydayFinancingOffers = [], lowCostOffer = [], totalPrice = 0, specialFinancingOffer = [], synchronySpecialFinancingCondition = false, specialFinancingEnable = false, checkoutShowEveryDayOffers, cashbackFinancingEnable = false, cashBackFinancingOffer = [] }: ISelectFinancingOfferData, returnCustomOfferArray?: boolean): IFinancingOfferFormatted | undefined => {
    let highestMinimumPurchase: number = 0;
    let bestOffer: IFinancingOfferFormatted = {
        numOfMonths: 0,
        costPerMonth: 0,
        isLTO: false,
        minPurchase: 0,
        everydayFinancingOfferMessage: '',
        limitedFinancingOfferMessage: '',
        cashbackFinancingOfferMessage: '',
        monthlyOfferDetails: '',
        financingOfferDescription: '',
        disclaimer: ''
    };
    let offerFound = false;

    let campaignFound: IFinancingOfferFormatted | undefined;

    const everydayOffers: IFinancingOfferFormatted[] | undefined = [];
    const specialOffer: IFinancingOfferFormatted[] | undefined = [];

    const validLimitedTimeFinancingOffers = limitedTimeFinancingOffers.filter((offer) => {
        return isValidLimitedTimeOffer(offer) && offer;
    });

    if (synchronySpecialFinancingCondition && specialFinancingEnable) {
        if (!offerFound || (!isScheduled(bestOffer) && specialFinancingOffer.length)) {
            // try default offer
            offerFound = false;
            highestMinimumPurchase = 0;
            specialFinancingOffer.forEach((offer: IFinancingOffer) => {
                const minPurchase = offer.minPurchase ? offer.minPurchase : 0;
                let currentOfferAccepted = false;
                if (totalPrice >= minPurchase && minPurchase >= highestMinimumPurchase) {
                    bestOffer = {
                        numOfMonths: offer.noInterestFor,
                        costPerMonth: Math.ceil(totalPrice / offer.noInterestFor),
                        startDate: offer.offerValidStartDate,
                        endDate: offer.offerValidEndDate,
                        isLTO: false,
                        minPurchase: offer.minPurchase || 0,
                        offerName: offer.offerName,
                        financingOfferDescription: offer.financingOfferDescription,
                        disclaimer: offer.disclaimer,
                        FinancingOfferDataOnPage: offer?.FinancingOfferDataOnPage,
                        checkoutDisplay: offer?.checkoutDisplay
                    };
                    highestMinimumPurchase = offer.minPurchase;
                    offerFound = true;
                    currentOfferAccepted = true;
                }
                if (returnCustomOfferArray && (totalPrice >= minPurchase)) {
                    specialOffer?.push({
                        numOfMonths: offer.noInterestFor,
                        costPerMonth: Math.ceil(totalPrice / offer.noInterestFor),
                        startDate: offer.offerValidStartDate,
                        endDate: offer.offerValidEndDate,
                        isLTO: false,
                        minPurchase: offer.minPurchase || 0,
                        everydayFinancingOfferMessage: offer.everydayFinancingOfferMessage,
                        limitedFinancingOfferMessage: offer.limitedFinancingOfferMessage,
                        cashbackFinancingOfferMessage: offer.cashbackFinancingOfferMessage,
                        financingOfferDescription: offer.financingOfferDescription,
                        disclaimer: offer.disclaimer,
                        offerName: offer.offerName
                    });
                    highestMinimumPurchase = offer.minPurchase;
                    offerFound = true;
                    currentOfferAccepted = true;
                    // @ts-ignore
                    bestOffer = specialOffer;
                }
                // Do not use default if end date is defined and has expired
                if (currentOfferAccepted && offer.offerValidEndDate) {
                    const endDate = dayjs(offer.offerValidEndDate, format);
                    const now = dayjs();
                    if (endDate.isValid() && now.isAfter(endDate)) {
                        offerFound = false;
                    }
                }
            });
        }
    } else {
        // if cashback enabled and has length check for cashback offer
        if (cashbackFinancingEnable && cashBackFinancingOffer?.length) {
            cashBackFinancingOffer.forEach((offer) => {
                const minPurchase = offer.minPurchase ? offer.minPurchase : 0;
                // if minimum purchase criteria is met, then create best offer
                if (totalPrice >= minPurchase) {
                    const cashBackOffer = {
                        numOfMonths: offer?.noInterestFor,
                        costPerMonth: Math.ceil(totalPrice / offer?.noInterestFor),
                        startDate: offer?.offerValidStartDate,
                        endDate: offer?.offerValidEndDate,
                        isLTO: false,
                        minPurchase: offer?.minPurchase || 0,
                        offerName: offer?.offerName,
                        financingOfferDescription: offer?.financingOfferDescription,
                        disclaimer: offer?.disclaimer,
                        FinancingOfferDataOnPage: offer?.FinancingOfferDataOnPage,
                        isCashBack: cashbackFinancingEnable,
                        checkoutDisplay: offer?.checkoutDisplay,
                        cashBackPercentageAmount: offer?.cashBackPercentageAmount,
                        cashbackBannerDetails: offer?.cashbackBannerDetails,
                        cashbackFinancingOfferMessage: offer?.cashbackFinancingOfferMessage
                    };
                    offerFound = true;
                    bestOffer = cashBackOffer;
                }

                // check if current offer expired
                if (offerFound && offer?.offerValidEndDate) {
                    const endDate = dayjs(offer?.offerValidEndDate, format);
                    const now = dayjs();
                    if (endDate?.isValid() && now.isAfter(endDate)) {
                        offerFound = false;
                    }
                }
            });
        }
        // if no offer found then check limited time financing offers
        if (!offerFound || !isScheduled(bestOffer)) {
            validLimitedTimeFinancingOffers.forEach((offer: IFinancingOffer) => {
                const minPurchase = offer.minPurchase ? offer.minPurchase : 0;
                if (totalPrice >= minPurchase && minPurchase > highestMinimumPurchase) {
                    const updatedOffer = calculateFinancing(totalPrice , offer.noInterestFor, offer.monthlyOfferDetails);
                    const currentOffer: IFinancingOfferFormatted = {
                        numOfMonths: offer.noInterestFor,
                        costPerMonth: updatedOffer?.monthlyPayment,
                        startDate: offer.offerValidStartDate,
                        endDate: offer.offerValidEndDate,
                        isLTO: true,
                        minPurchase: offer.minPurchase,
                        everydayFinancingOfferMessage: offer.everydayFinancingOfferMessage,
                        limitedFinancingOfferMessage: offer.limitedFinancingOfferMessage,
                        cashbackFinancingOfferMessage: offer.cashbackFinancingOfferMessage,
                        monthlyOfferDetails: updatedOffer?.financingMessage,
                        financingOfferDescription: offer.financingOfferDescription,
                        disclaimer: offer.disclaimer,
                        specialCampaign: offer.specialCampaignOffer,
                        checkoutDisplay: offer.checkoutDisplay,
                        offerName: offer.offerName,
                        specialCase36Months: offer.noInterestFor === 36
                    };
                    bestOffer = currentOffer;
                    highestMinimumPurchase = offer.minPurchase;
                    offerFound = true;

                    // check if campaign schedule is scheduled, otherwise fall back to parent offer
                    const validCampaign = getValidSpecialCampaign(currentOffer);
                    if (validCampaign) {
                        currentOffer.startDate = validCampaign.campaignValidStartDate;
                        currentOffer.endDate = validCampaign.campaignValidEndDate;
                        campaignFound = currentOffer;
                    }
                    else {
                        campaignFound = undefined;
                    }
                }
            });
        }
        // if no offer found then check everyday financing offer
        if (!offerFound || (!isScheduled(bestOffer) && everydayFinancingOffers.length)) {
            // try default offer
            offerFound = false;
            highestMinimumPurchase = 0;

            everydayFinancingOffers.forEach((offer: IFinancingOffer) => {
                const minPurchase = checkoutShowEveryDayOffers ? 0 : offer.minPurchase ? offer.minPurchase : 0;
                let currentOfferAccepted = false;
                if (totalPrice >= minPurchase && minPurchase >= highestMinimumPurchase) {
                    bestOffer = {
                        numOfMonths: offer.noInterestFor,
                        costPerMonth: Math.ceil(totalPrice / offer.noInterestFor),
                        isLTO: false,
                        minPurchase: offer.minPurchase || 0,
                        everydayFinancingOfferMessage: offer.everydayFinancingOfferMessage,
                        limitedFinancingOfferMessage: offer.limitedFinancingOfferMessage,
                        cashbackFinancingOfferMessage: offer.cashbackFinancingOfferMessage,
                        offerName: offer.offerName,
                        monthlyOfferDetails: offer.monthlyOfferDetails,
                        financingOfferDescription: offer.financingOfferDescription,
                        disclaimer: offer.disclaimer
                    };
                    highestMinimumPurchase = offer.minPurchase;
                    offerFound = true;
                    currentOfferAccepted = true;
                }

                if (returnCustomOfferArray && (totalPrice >= minPurchase)) {
                    everydayOffers?.push({
                        numOfMonths: offer.noInterestFor,
                        costPerMonth: Math.ceil(totalPrice / offer.noInterestFor),
                        isLTO: false,
                        minPurchase: offer.minPurchase || 0,
                        everydayFinancingOfferMessage: offer.everydayFinancingOfferMessage,
                        limitedFinancingOfferMessage: offer.limitedFinancingOfferMessage,
                        cashbackFinancingOfferMessage: offer.cashbackFinancingOfferMessage,
                        monthlyOfferDetails: offer.monthlyOfferDetails,
                        financingOfferDescription: offer.financingOfferDescription,
                        disclaimer: offer.disclaimer,
                        offerName: offer.offerName
                    });
                    highestMinimumPurchase = offer.minPurchase;
                    offerFound = true;
                    currentOfferAccepted = true;
                    // @ts-ignore
                    bestOffer = everydayOffers;
                }
                // Do not use default if end date is defined and has expired
                if (currentOfferAccepted && offer.offerValidEndDate) {
                    const endDate = dayjs(offer.offerValidEndDate, format);
                    const now = dayjs();
                    if (endDate.isValid() && now.isAfter(endDate)) {
                        offerFound = false;
                    }
                }
            });
        }
        if (lowCostOffer?.length) {
            lowCostOffer.forEach((offer: ILowCostOffer) => {
                if (totalPrice <= offer.maxPurchase && offer.offerName?.toLowerCase() === 'low cost offer') {
                    const LCO = {
                        offerName: offer.offerName,
                        maxPurchase: offer.maxPurchase || 0,
                        lowCostFinancingOfferMessage: offer.lowCostFinancingOfferMessage,
                        financingOfferDescription: offer?.financingOfferDescription?.replace(/{defaultNumOfMonths}/g, `${offer?.defaultNumberOfMonths?.toString() || 6}`),
                        disclaimer: offer.disclaimer,
                        lowCostOfferDetailsOnCart: offer.lowCostOfferDetailsOnCart
                    };
                    highestMinimumPurchase = offer.maxPurchase;
                    offerFound = true;
                    // @ts-ignore
                    bestOffer = LCO;
                }
            });
        }
    }

    if (!offerFound) {
        return undefined;
    }
    if (campaignFound) {
        return campaignFound;
    }
    return bestOffer;
};
